import { Card, CardContent, CardHeader } from '@mui/material'
import React from 'react'
import { LineChart } from '@mui/x-charts'

const Stats = () => {
    return (
        <Card sx={{ height: '100%' }}>
            <CardHeader
                title={'Stats at a glance'}
            />
            <CardContent>
                <LineChart
                    xAxis={[{ data: [1, 2, 3, 5, 8, 10], disableLine: true, disableTicks: true }]} // Hides x-axis
                    yAxis={[{ disableLine: true, disableTicks: true }]} // Hides y-axis
                    title='Line Chart'
                    series={[
                        { data: [2, 5.5, 2, 8.5, 1.5, 5] },
                        { data: [4, 11, 4, 17, 3, 10] },
                        { data: [7, 14, 7, 20, 6, 13]}
                    ]}
                    // width={500}
                    height={300}
                />
            </CardContent>
        </Card>
    )
}

export default Stats