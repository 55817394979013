export const cardDataMonthly = [
    {
        image: "https://i.imgur.com/Ql4jRdB.png",
        title: "Standard",
        price: "$19/mo",
        caption: 'billed monthly',
        features: [
            { "ordersPerMonth": 3000 },
            { "Follow-upEmails": 150},
            { "AdditionalSellerAccounts": 4},
            { "AdditionalUsers": 1 },
            { "AutomatedReports": 3 },
            { "ManageUserAccess": false },
            { "Real-timeProfitDashboard": true },
            { "ListingChangeAlerts": true },
            { "PPCOptimization": true },
            { "InventoryManagement": true },
            { "RefundsLostInventory": true },
            { "LTVDashboard": true },
        ],
    },
    {
        image: "https://i.imgur.com/pJNFEHR.png",
        title: "Professional",
        price: "$29/mo",
        caption: 'billed monthly',
        features: [
            { "ordersPerMonth": 6000 },
            { "Follow-upEmails": 6000 },
            { "AdditionalSellerAccounts": 6 },
            { "AdditionalUsers": 2},
            { "AutomatedReports": 6 },
            { "ManageUserAccess": false },
            { "Real-timeProfitDashboard": true },
            { "ListingChangeAlerts": true },
            { "PPCOptimization": true },
            { "InventoryManagement": true },
            { "RefundsLostInventory": true },
            { "LTVDashboard": true },
        ],
    },
    {
        image: "https://i.imgur.com/Hg0sUJP.png",
        title: "Business",
        price: "$39/mo",
        caption: 'billed monthly',
        features: [
            { "ordersPerMonth": 15000 },
            { "Follow-upEmails": 15000 },
            { "AdditionalSellerAccounts": 8 },
            { "AdditionalUsers": 4},
            { "AutomatedReports": 8 },
            { "ManageUserAccess": false },
            { "Real-timeProfitDashboard": true },
            { "ListingChangeAlerts": true },
            { "PPCOptimization": true },
            { "InventoryManagement": true },
            { "RefundsLostInventory": true },
            { "LTVDashboard": true },
        ],
    },
    {
        image: "https://i.imgur.com/Hg0sUJP.png",
        title: "Enterprise",
        price: "$79/mo",
        caption: 'billed monthly',
        features: [
            { "ordersPerMonth": 50000 },
            { "Follow-upEmails": 30000 },
            { "AdditionalSellerAccounts": 16 },
            { "AdditionalUsers": 4},
            { "AutomatedReports": 16 },
            { "ManageUserAccess": true },
            { "Real-timeProfitDashboard": true },
            { "ListingChangeAlerts": true },
            { "PPCOptimization": true },
            { "InventoryManagement": true },
            { "RefundsLostInventory": true },
            { "LTVDashboard": true },
        ],
    },
];

export const cardDataYearly = [
    {
        image: "https://i.imgur.com/Ql4jRdB.png",
        title: "Standard",
        price: "$15/mo",
        caption: '$179 billed annually',
        features: [
            { "ordersPerMonth": 3000 },
            { "Follow-upEmails": 150},
            { "AdditionalSellerAccounts": 4},
            { "AdditionalUsers": 1 },
            { "AutomatedReports": 3 },
            { "ManageUserAccess": false },
            { "Real-timeProfitDashboard": true },
            { "ListingChangeAlerts": true },
            { "PPCOptimization": true },
            { "InventoryManagement": true },
            { "RefundsLostInventory": true },
            { "LTVDashboard": true },
        ],
    },
    {
        image: "https://i.imgur.com/pJNFEHR.png",
        title: "Professional",
        price: "$23/mo",
        caption: '$279 billed annually',
        features: [
            { "ordersPerMonth": 6000 },
            { "Follow-upEmails": 6000 },
            { "AdditionalSellerAccounts": 6 },
            { "AdditionalUsers": 2},
            { "AutomatedReports": 6 },
            { "ManageUserAccess": false },
            { "Real-timeProfitDashboard": true },
            { "ListingChangeAlerts": true },
            { "PPCOptimization": true },
            { "InventoryManagement": true },
            { "RefundsLostInventory": true },
            { "LTVDashboard": true },
        ],
    },
    {
        image: "https://i.imgur.com/Hg0sUJP.png",
        title: "Business",
        price: "$31/mo",
        caption: '$369 billed annually',
        features: [
            { "ordersPerMonth": 15000 },
            { "Follow-upEmails": 15000 },
            { "AdditionalSellerAccounts": 8 },
            { "AdditionalUsers": 4},
            { "AutomatedReports": 8 },
            { "ManageUserAccess": false },
            { "Real-timeProfitDashboard": true },
            { "ListingChangeAlerts": true },
            { "PPCOptimization": true },
            { "InventoryManagement": true },
            { "RefundsLostInventory": true },
            { "LTVDashboard": true },
        ],
    },
    {
        image: "https://i.imgur.com/Hg0sUJP.png",
        title: "Enterprise",
        price: "$63/mo",
        caption: '$759 billed annually',
        features: [
            { "ordersPerMonth": 50000 },
            { "Follow-upEmails": 30000 },
            { "AdditionalSellerAccounts": 16 },
            { "AdditionalUsers": 4},
            { "AutomatedReports": 16 },
            { "ManageUserAccess": true },
            { "Real-timeProfitDashboard": true },
            { "ListingChangeAlerts": true },
            { "PPCOptimization": true },
            { "InventoryManagement": true },
            { "RefundsLostInventory": true },
            { "LTVDashboard": true },
        ],
    },
];