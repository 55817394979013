import { Navigation } from "../../../../interfaces/Navigation";

export const Navigations: Navigation[] = [
    { label: 'Home', path: '/' },
    { label: 'Features', path: '#features' },
    // { label: 'Testimonial', path: '#testimonials' },
    { label: 'Team', path: '#team' },
    { label: 'FAQ', path: '#faq' },
    { label: 'Contact us', path: '#contact-us' },
    { label: 'Pricing', path: '#pricing' },
]
