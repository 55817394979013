import { Box, Button, Container, Grid, Tab, Tabs, Typography, Card, CardContent, CardMedia, CardActions } from '@mui/material';
import React from 'react';
import { cardDataMonthly, cardDataYearly } from './PricingData';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Pricing: React.FC = () => {

    const [value, setValue] = React.useState(1);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box id="#pricing" sx={{ pb: { xs: 6, md: 4 }, backgroundColor: 'background.paper' }}>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={2}>
                        <Typography
                            component="h2"
                            sx={{
                                fontSize: { xs: 36, md: 46 },
                                mt: { xs: 0, md: 7 },
                                mb: 4,
                                lineHeight: 1,
                                fontWeight: 'bold',
                            }}
                        >
                            Pricing
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                            <Tabs value={value} onChange={handleChange} aria-label="PricingOption">
                                <Tab label="Monthly" {...a11yProps(0)} />
                                <Tab label="Annually" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <CustomTabPanel value={value} index={0}>
                            <Grid container spacing={2}>
                                {cardDataMonthly.map((card, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Card>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={card.image}
                                                alt={card.title}
                                            />
                                            <CardContent>
                                                <Typography variant="h6" component="div">
                                                    {card.title}
                                                </Typography>
                                                <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                                                    {card.price}
                                                </Typography>
                                                <Box>
                                                    {card.features.map((feature, index) => (
                                                        <Typography key={index} variant="body2" color="text.secondary">
                                                            <p>{feature.AdditionalSellerAccounts}</p>
                                                            <p>{feature.AdditionalUsers}</p>
                                                            <p>{feature.AutomatedReports}</p>
                                                            <p>{feature['Follow-upEmails']}</p>
                                                            <p>{feature.InventoryManagement}</p>
                                                            <p>{feature.LTVDashboard}</p>
                                                            <p>{feature.ListingChangeAlerts}</p>
                                                            <p>{feature.ManageUserAccess}</p>
                                                            <p>{feature.PPCOptimization}</p>
                                                            <p>{feature['Real-timeProfitDashboard']}</p>
                                                            <p>{feature.RefundsLostInventory}</p>
                                                            <p>{feature.ordersPerMonth}</p>
                                                        </Typography>
                                                    ))}
                                                </Box>
                                            </CardContent>
                                            <CardActions>
                                                <Button size="small" variant="contained">Start Trial</Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Grid container spacing={2}>
                                {cardDataYearly.map((card, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Card>
                                            <CardMedia
                                                component="img"
                                                height="140"
                                                image={card.image}
                                                alt={card.title}
                                            />
                                            <CardContent>
                                                <Typography variant="h6" component="div">
                                                    {card.title}
                                                </Typography>
                                                <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                                                    {card.price}
                                                </Typography>
                                                <Box>
                                                    {card.features.map((feature, index) => (
                                                        <Typography key={index} variant="body2" color="text.secondary">
                                                            <p>{feature.AdditionalSellerAccounts}</p>
                                                            <p>{feature.AdditionalUsers}</p>
                                                            <p>{feature.AutomatedReports}</p>
                                                            <p>{feature['Follow-upEmails']}</p>
                                                            <p>{feature.InventoryManagement}</p>
                                                            <p>{feature.LTVDashboard}</p>
                                                            <p>{feature.ListingChangeAlerts}</p>
                                                            <p>{feature.ManageUserAccess}</p>
                                                            <p>{feature.PPCOptimization}</p>
                                                            <p>{feature['Real-timeProfitDashboard']}</p>
                                                            <p>{feature.RefundsLostInventory}</p>
                                                            <p>{feature.ordersPerMonth}</p>
                                                        </Typography>
                                                    ))}
                                                </Box>
                                            </CardContent>
                                            <CardActions>
                                                <Button size="small" variant="contained">Start Trial</Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </CustomTabPanel>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Pricing;
