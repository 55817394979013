import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Typography, } from '@mui/material'
import { FC, useState } from 'react'
import { data } from './FAQsData'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const HomeFAQs: FC = () => {
    const [expanded, setExpanded] = useState<string | false>('');

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <Box id="#faq" sx={{ py: { xs: 10, md: 14 }, background: 'background.paper' }}>
            <Container>
                <Grid container spacing={3}>
                    <Grid >
                        <Typography
                            component="h2"
                            sx={{
                                position: 'relative',
                                fontSize: { xs: 40, md: 50 },
                                ml: { xs: 4, md: 4 },
                                mt: 2,
                                mb: 3,
                                lineHeight: 1,
                                fontWeight: 'bold',
                            }}
                        >
                            Frequently Asked{' '}
                            <Typography
                                component="mark"
                                sx={{
                                    position: 'relative',
                                    color: 'primary.main',
                                    fontSize: 'inherit',
                                    fontWeight: 'inherit',
                                    backgroundColor: 'unset',
                                }}
                            >
                                Questions <br />
                            </Typography>
                        </Typography>

                        
                        <Typography sx={{ color: 'text.secondary', mb: 2, ml: { xs: 4, md: 4 } }}>
                            Browse through these FAQs to find answers to commonly asked questions.
                        </Typography>
                        {/* <Divider /> */}

                        <Grid container sx={{ ml: { xs: 2, md: 2 } }}>
                            {data.map(({ question, answer }, index) => (
                                <Accordion
                                    expanded={expanded === `panel${index}`}
                                    onChange={handleChange(`panel${index}`)}
                                    sx={{width: '100%'}}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}-content`}
                                        id={`panel${index}-header`}
                                    >
                                        <Typography variant="h5" sx={{ mb: 1 }}>{question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ lineHeight: 1.3, color: 'text.secondary' }} variant="subtitle1">{answer}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default HomeFAQs