import React from 'react'
import MainLayout from '../Layout'
import { Box, Container, Grid, Typography } from '@mui/material'
import PageContainer from '../../../components/PageContainer/PageContainer'
import { Link } from 'react-router-dom'
import { Link as ScrollLink, Element } from 'react-scroll'
import { paths } from '../../../paths'

const PrivacyPolicy: React.FC = () => {
    return (
        <MainLayout>
            <PageContainer title='Privacy Policy | Virtacc' description='This is Privacy Policy page of Virtacc'>
                <Box id="head" sx={{ backgroundColor: 'background.paper', position: 'relative', pt: 4, pb: { xs: 8, md: 10 } }}>
                    <Container maxWidth="lg">
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    // textAlign: { xs: 'center', md: 'left' },
                                    textAlign: 'left',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box sx={{ mb: 3 }}>
                                    <Typography
                                        component="h6"
                                        sx={{
                                            position: 'relative',
                                            fontSize: { xs: 30, md: 45 },
                                            letterSpacing: 1.5,
                                            fontWeight: 'bold',
                                            lineHeight: 1.3,
                                        }}
                                    >
                                        Privacy Policy
                                    </Typography>
                                </Box>

                                {/* OVERVIEW */}
                                <Box>
                                    <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                        Overview
                                    </Typography>
                                </Box>
                                <Box sx={{ mb: 3 }} >
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        <p>Virtacc - Your Virtual Accountant ("Virtacc"), owns and operates the Virtacc business, including the Virtacc platform, analytics, information, applications, websites and other products and services (collectively, the “Services”). All references to “we”, “us”, this “website” or this “site” refer to Virtacc. Capitalized terms used, but not defined, in this Privacy Policy are defined in the <Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} to={'/terms-of-service'} >Terms & Conditions</Box>. This Privacy Policy is supplemented by and subject to the Terms & Conditions.</p>
                                        <p>Our Services are designed for businesses and are not intended for personal, family or household use. Accordingly, we treat all personal information covered by this Privacy Policy, including information about any visitors to our website, as pertaining to individuals acting as business representatives, rather than in their personal capacity.</p>
                                        <p>Still, we do receive limited personal information in providing the Services.  So, this Privacy Policy describes:</p>
                                        <ul>
                                            <li><Box sx={{ color: 'primary.main', cursor: 'pointer' }} component={ScrollLink} to={'info_we_collect'} smooth={true} width={'fit-content'} spy={true} duration={350} >what personal information we collect;</Box></li>
                                            <li><Box sx={{ color: 'primary.main', cursor: 'pointer' }} component={ScrollLink} to={'how_we_collect_info'} smooth={true} width={'fit-content'} spy={true} duration={350} >how we collect it;</Box></li>
                                            <li><Box sx={{ color: 'primary.main', cursor: 'pointer' }} component={ScrollLink} to={'how_we_use_info'} smooth={true} width={'fit-content'} spy={true} duration={350} >how we use it;</Box></li>
                                            <li><Box sx={{ color: 'primary.main', cursor: 'pointer' }} component={ScrollLink} to={'how_when_we_share_info'} smooth={true} width={'fit-content'} spy={true} duration={350} >how and when we share it;</Box></li>
                                            <li><Box sx={{ color: 'primary.main', cursor: 'pointer' }} component={ScrollLink} to={'how_to_changeDelete_data'} smooth={true} width={'fit-content'} spy={true} duration={350} >how to change and/or delete data;</Box></li>
                                            <li><Box sx={{ color: 'primary.main', cursor: 'pointer' }} component={ScrollLink} to={'how_to_contact_us'} smooth={true} width={'fit-content'} spy={true} duration={350} >how to contact us.</Box></li>
                                        </ul>
                                        <p>For information on third-party interest-based advertising conducted through our Services, please see the “Targeted Advertising” section below.</p>
                                        <p>We reserve the right to change this Privacy Policy at any time.   We may inform you about any revisions.  All changes are effective on the date listed at the top of this page and will apply to all information that we have about you.  Your continued use of the Services after any change is posted indicates your acceptance of this Privacy Policy and your continued consent to our processing of your Personal Information in the manner set forth herein.  If at any point you do not agree to any portion of the Privacy Policy, you should immediately stop using the Services.  Because this Privacy Policy contains legal obligations we encourage you to review this Privacy Policy carefully.  If you have any questions concerning this Privacy Policy or our information collection, storage and disclosure practices, please contact us at <Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} to={'mailto:support@virtacc.com'} >support@virtacc.com</Box>.</p>
                                    </Typography>
                                </Box>

                                {/* INFORMATION WE COLLECT */}
                                <Box>
                                    <Element name='info_we_collect' id='info_we_collect'>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            INFORMATION WE COLLECT
                                        </Typography>
                                    </Element>
                                </Box>
                                <Box sx={{ mb: 3 }}>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        <ul>
                                            <li>Personal Information. “Personal Information” is information that may be used to directly or indirectly identify an individual (which in some cases, may include certain device information).  The Personal Information we collect may include your (a) name, postal address, email address, and phone number; (b) Internet Protocol (IP) address, device ID or other persistent identifiers tied to your computer or device; and (c) information about your third party accounts (“Third Party Accounts”) you may choose to provide us for integration and analytics purposes. In general, Personal Information we collect includes, but is not limited to:</li>
                                            <ul>
                                                <li>Personal Information needed for you to be able to use the Services, including for establishing an account, logging in, paying for subscription to the Services, and linking your Third Party Accounts;</li>
                                                <li>Personal Information to contact and respond to you, including to provide you with results of our analytics services, reply to your inquiries, and keep in touch with you regarding features or matters of interest regarding the Services;</li>
                                                <li>Un-identifiable and aggregated Personal Information pertaining to your visits to and use of the Services and Site that help us maintain the appropriate features, functionality and user experience</li>
                                            </ul>
                                            <li>Usage Data. “Usage Data” is information passively or automatically collected by us through your use of the Services.  Usage Data may be collected using cookies, web beacons, page tags or similar tools.  All Usage Data is anonymous transactional data that is not associated with any users as individuals.  Such Usage Data may include: your Internet Protocol (IP) address, mobile identifiers, browser type, and internet service provider (ISP); your operating system; which of our web pages you access and how frequently you access them; referral or exit pages; click stream data; and the dates and times that you visit the Services or Site.</li>
                                            <li>Customer Data  In order to provide you with our services, and with your permission (which you are providing if you link any of your Third Party Platforms to your Company account), we may collect and process data regarding your customers and their use of your services (“Customer Data”).  How we treat that Customer Data is set forth in the “Customer Data” section of our <Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} to={paths.legals.termsOfService} >Terms & Conditions</Box>.  As set forth in more detail in our Terms & Conditions, you represent and warrant that all Customer Data was collected by you in accordance with your privacy policy and all applicable laws and that you have the right to provide us with such Customer Data for the purposes set forth in the Terms & Conditions.  For clarity, your Third Party Platform accounts are governed by their own privacy policies and are not under the control of Company.</li>
                                        </ul>
                                        <p>Our data protection declaration should be legible and understandable for the general public, as well as our customers and business partners. To ensure this, we would like to first explain the terminology used.</p>
                                        <p>In this data protection declaration, we use the following terms:</p>
                                    </Typography>
                                </Box>

                                {/* HOW AND WHEN WE COLLECT INFORMATION */}
                                <Box>
                                    <Element name='how_we_collect_info' id='how_we_collect_info' >
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            HOW AND WHEN WE COLLECT INFORMATION
                                        </Typography>
                                    </Element>
                                </Box>
                                <Box sx={{ mb: 3 }}>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        <ul>
                                            <li>Personal Information. We collect Personal Information at the time you provide it to us. We collect Personal Information through sign-up forms and as part of your registration for an account, product, or service, promotion, or contest from this website. In addition, we collect personal information from communications with site visitors as well as from third-party outside sources including data brokers and aggregators.</li>
                                            <li>Usage Data. We use cookies when you sign in to keep track of your personal session.  We also use cookies to track your activity on the Services, including your interactions with emails we send, and via social media platforms, third-party platforms, applications, integrations, and services of our marketing partners to provide you with the optimal experience.   This data may be passively or automatically collected (that is, gathered without your actively providing the information) using various analytics and reporting technologies, such as cookies, web beacons, locally stored objects, and mobile device identifiers and SDKs, and other similar methodologies as well as similar technologies developed in the future.</li>
                                            <li>“Do Not Track” Requests.  Some Web browsers have a “Do Not Track” feature that signals to websites that you do not want to have your online activity tracked. Because each browser communicates “Do Not Track” signals differently, we do not respond to “Do Not Track” signals at this time.</li>
                                        </ul>
                                    </Typography>
                                </Box>

                                {/* B) HOW WE USE INFORMATION */}
                                <Box>
                                    <Element name='how_we_use_info' id='how_we_use_info'>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            HOW WE USE INFORMATION
                                        </Typography>
                                    </Element>
                                </Box>
                                <Box sx={{ mb: 3 }}>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        <ul>
                                            <li>We may use your Personal Information and Usage Data to take actions you request and for the basic purposes of the Services.  We may use your Personal Information in connection with other products or services we may offer, with our internal reporting for this site (such as security assessments), or to contact you with promotions regarding other products or services offered by us, our affiliated entities or our third party partners.  We may, for example, use aggregated Usage Data information to optimize the Services or to evaluate the success of particular marketing and advertising campaigns or search engine optimization strategies.</li>
                                            <li>We may also send you messages related to certain features or your activity on this site. We may also send you news or updates about changes to our Services. By default, you will receive these messages via email.</li>
                                            <li>We may use publicly available Personal Information posted on social media profile information including photos for purposes of assisting us, and our marketing partners with marketing and advertising activities and with contact management.</li>
                                            <li>We may use Usage Data without restriction in our sole discretion for administrative and optimization purposes, such as to improve the Services and personalize ads (as described more particularly below under “Targeted Advertising”).</li>
                                            <li>We may combine and use the Personal Information and Usage Data we collect from all services and products offered by us and our Affiliated Entities over various websites to provide, protect, and improve them, and to develop and offer new services and products. We will treat you as a single user of these combined services and products.</li>
                                        </ul>
                                    </Typography>
                                </Box>

                                {/* HOW AND WHEN WE SHARE AND DISCLOSE INFORMATION  */}
                                <Box>
                                    <Element name='how_when_we_share_info' id='how_when_we_share_info'>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            HOW AND WHEN WE SHARE AND DISCLOSE INFORMATION
                                        </Typography>
                                    </Element>
                                </Box>
                                <Box sx={{ mb: 3 }}>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        <ul>
                                            <li>General Disclosure Policy. We may share and disclose your Personal Information as described below.  We may share and disclose Usage Data without restriction, such as in the ways described below.</li>
                                            <li>Affiliated Entities. “Affiliated Entities” are entities that we legally control (by voting rights) or that control us.  We may provide your Personal Information and Usage Data to any affiliated entities we may have, including our subsidiaries.</li>
                                            <li>Service Providers. We may provide access to your Personal Information and Usage Data to trusted service providers that assist us with the operation and maintenance of the Service. For example, we may contract with third parties to facilitate purchases from the Services, process payments, host our servers, provide security, and provide production, fulfillment, optimization, analytics, reporting, and software maintenance and development services. Our service providers will be given access to your information only as is reasonably necessary to provide the services for which they are contracted.</li>
                                            <li>Successors. If we sell or otherwise transfer part or all of our business or assets to another organization, such as in the course of an acquisition, merger, bankruptcy or liquidation, we may transfer your Personal Information and Usage Data.</li>
                                            <li>Legal Process, Enforcement and Security Notice. We may disclose your Personal Information and Usage Data if we have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary (i) to satisfy any applicable law, regulation, legal process or enforceable governmental request (such as for example, to comply with a subpoena or court order), (ii) to detect, prevent, and address fraud or other illegal activity, and (iii) to investigate, respond to, or enforce violations of our rights or the security of this site.</li>
                                            <li>With Your Consent. We may share your Personal Information with other parties with your consent.</li>
                                            <li>Analytics Partners.  We may participate with third party analytics partners to monitor and analyze Web traffic and to keep track of user behavior on this site.</li>
                                            <li>Transfer Outside Country of Residence.  In general, any Personal Information we may collect may be stored and processed in our servers located in the United States or in any other country in which we, or our affiliates, subsidiaries, or agents maintain facilities. By using this site, you consent to any such transfer of Personal Information outside your country of residence to any such location.</li>
                                        </ul>
                                    </Typography>
                                </Box>

                                {/* TARGETED ADVERTISING */}
                                <Box>
                                    <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                        TARGETED ADVERTISING
                                    </Typography>
                                </Box>
                                <Box sx={{ mb: 3 }}>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        <ul>
                                            <li>We engage certain third-party service providers to serve advertisements on our behalf across the Internet and to provide analytics services. We may also participate in third-party affiliate advertising and allow third-party affiliate links to be encoded on some of our pages. This means that we may earn a commission when you click on or make purchases via third-party affiliate links.</li>
                                            <li>Our advertisers or the ad networks that serve advertisements may utilize cookies or other similar tracking technologies (including within the ads) to collect anonymous information from you such as your device identifiers, advertising IDs, and IP address, web browser, actions you take relating to the ads, any links you click on, and conversion information.  This information may be used by us, our service providers and their clients in aggregated, anonymous form to, among other things, analyze and track aggregated data, determine the popularity of certain content or products, measure the effectiveness of ad campaigns, determine the proper amount of repeat views of a given ad, and deliver advertising and content targeted to your interests on our Services and outside of our Services on other websites (also known as “interest-based advertising”). These service providers are prohibited from collecting any Personal Data from you and we do not share any of your Personal Data with them.</li>
                                            <li>You have a choice about participating in interest-based advertising. If you wish to opt out of such participation, you have a few options:</li>
                                            <ul>
                                                <li>On your mobile device, you can visit <Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} target='_blank' to={'https://youradchoices.com/appchoices'} >https://youradchoices.com/appchoices</Box> to learn about and download the Digital Advertising Alliance’s opt-out app, which allows you to opt your mobile device out of interest-based advertising from participating companies.</li>
                                                <li>Your device settings may allow you to limit the use of information from your device in interest-based advertising through your browser’s settings (such as under the “Security & Privacy” settings of the Safari app on an iOS device) or an “Opt Out of Interest-Based Ads” setting (on an Android device).</li>
                                                <li>You can learn more about advertising networks and interest-based advertising, and your ability to opt out, by visiting the Digital Advertising Alliance at <Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} target='_blank' to={'https://www.aboutads.info/choices'} >www.aboutads.info/choices</Box> or the Network Advertising Initiative at <Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} target='_blank' to={'https://www.networkadvertising.org/choices'} >www.networkadvertising.org/choices</Box>.</li>
                                            </ul>
                                            <li>In providing you with transparency and choice regarding interest-based advertising, we are acting in accordance with our commitment to the Digital Advertising Alliance’s Self-Regulatory Principles. To learn more about these Principles, please visit <Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} target='_blank' to={'https://www.aboutads.info/consumers'} >https://www.aboutads.info/consumers</Box>.</li>
                                        </ul>
                                    </Typography>
                                </Box>

                                {/* CHANGING AND DELETING PERSONAL INFORMATION */}
                                <Box>
                                    <Element name='how_to_changeDelete_data' id='how_to_changeDelete_data'>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            CHANGING AND DELETING PERSONAL INFORMATION
                                        </Typography>
                                    </Element>
                                </Box>
                                <Box sx={{ mb: 3 }}>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        <ul>
                                            <li>Under certain laws, including as described below with respect to the GDPR and CCPA, you may have the right to: obtain confirmation that we hold Personal Information about you, request access to and receive information about the Personal Information we maintain about you, receive copies of the Personal Information we maintain about you, update and correct inaccuracies in your Personal Information, object to the continued processing of your Personal Information, and have the Personal Information blocked, anonymized or deleted, as appropriate. The right to access Personal Information may be limited in some circumstances by local law, including as described above under California law. If you qualify, in order to exercise these rights, please contact us as described under “Contact Us”.</li>
                                            <li>You may opt out of promotional communications by sending us an email at the email address below under “Contact Us”.</li>
                                            <li>We may ask you to provide additional information for identity verification purposes, or to verify that you are in possession of an applicable email account.</li>
                                            <li>Please understand, however, that we reserve the right to retain an archive of such Personal Information for a commercially reasonable time to ensure that its deletion does not affect the integrity of our data; and we further reserve the right to retain an anonymous version of such Information.</li>
                                        </ul>
                                    </Typography>
                                </Box>

                                {/* CONTACT US */}
                                <Box>
                                    <Element name='how_to_contact_us' id='how_to_contact_us'>
                                        <Typography sx={{ color: 'text.secondary', fontSize: 25, fontWeight: "bold" }}>
                                            CONTACT US
                                        </Typography>
                                    </Element>
                                </Box>
                                <Box sx={{ mb: 3 }}>
                                    <Typography sx={{ color: 'text.secondary' }}>
                                        <p>If you have any questions regarding this Privacy Policy, please contact us at:</p>
                                        <p>VIRTACC Your Virtual Accountant</p>
                                        <p>E-96, LGF, Block E</p>
                                        <p>Kalkaji, New Delhi</p>
                                        <p>Delhi 110019</p>
                                        <p>Contact: <Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} to={'mailto:support@virtacc.com'} >support@virtacc.com</Box></p>
                                    </Typography>
                                </Box>

                            </Box>
                        </Grid>
                    </Container>
                </Box>
            </PageContainer>
        </MainLayout>
    )
}

export default PrivacyPolicy