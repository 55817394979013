import React, { FC, useState } from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { Menu, Close } from '@mui/icons-material'
import { Logo } from '../../../components/Core/Logo'
import { Navigation, AuthNavigation, DashboardNavigation } from '../../../components/Home/Header/Navigation'
import { UserPayload } from '../../../interfaces/UserPayload'
import { Helper } from '../../../utils/Helper'
import { Link } from 'react-router-dom'
import { paths } from '../../../paths'

const Header: FC = () => {
    const [visibleMenu, setVisibleMenu] = useState<boolean>(false)
    const { breakpoints } = useTheme()
    const matchMobileView = useMediaQuery(breakpoints.down('md'))

    const [user, setUser] = React.useState<UserPayload>({ email: '', role: 1, organization: ''});
    
    React.useEffect(() => {
        const currentUser: UserPayload = Helper.getCurrentUser();
        setUser(currentUser);
    },[])

    return (
        <Box sx={{ backgroundColor: 'background.paper' }}>
            <Container sx={{ py: { xs: 2, md: 3 } }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box component={Link} to={paths.home}>
                        <Logo color="light" height={32} width={122} />
                    </Box>
                    
                    <Box sx={{ ml: 'auto', display: { xs: 'inline-flex', md: 'none' } }}>
                        <IconButton onClick={() => setVisibleMenu(!visibleMenu)}>
                            <Menu />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexDirection: { xs: 'column', md: 'row' },

                            transition: (theme) => theme.transitions.create(['top']),
                            ...(matchMobileView && {
                                py: 6,
                                backgroundColor: 'background.paper',
                                zIndex: 'appBar',
                                position: 'fixed',
                                height: { xs: '100vh', md: 'auto' },
                                top: visibleMenu ? 0 : '-120vh',
                                left: 0,
                            }),
                        }}
                    >
                        <Box /> {/* Magic space */}
                        <Navigation />
                        {user && <DashboardNavigation />}
                        {!user && <AuthNavigation />}
                        {visibleMenu && matchMobileView && (
                            <IconButton
                                sx={{
                                    position: 'fixed',
                                    top: 10,
                                    right: 10,
                                }}
                                onClick={() => setVisibleMenu(!visibleMenu)}
                            >
                                <Close />
                            </IconButton>
                        )}
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default Header

// Sticky Header

// import React, { FC, useState } from 'react'
// import Box from '@mui/material/Box'
// import Container from '@mui/material/Container'
// import IconButton from '@mui/material/IconButton'
// import useMediaQuery from '@mui/material/useMediaQuery'
// import { useTheme } from '@mui/material/styles'
// import { Menu, Close } from '@mui/icons-material'
// import { Logo } from '../../../components/Core/Logo'
// import { Navigation, AuthNavigation, DashboardNavigation } from '../../../components/Home/Header/Navigation'
// import { UserPayload } from '../../../interfaces/UserPayload'
// import { Helper } from '../../../utils/Helper'
// import { Link } from 'react-router-dom'
// import { paths } from '../../../paths'

// const Header: FC = () => {
//     const [visibleMenu, setVisibleMenu] = useState<boolean>(false)
//     const { breakpoints } = useTheme()
//     const matchMobileView = useMediaQuery(breakpoints.down('md'))

//     const [user, setUser] = React.useState<UserPayload>({ email: '', role: 1, organization: ''})
    
//     React.useEffect(() => {
//         const currentUser: UserPayload = Helper.getCurrentUser();
//         setUser(currentUser);
//     }, [])

//     return (
//         <Box
//             sx={{
//                 backgroundColor: 'background.paper',
//                 position: 'sticky',
//                 top: 0,
//                 zIndex: 1100, // Ensure this is higher than other content
//                 width: '100%',
//             }}
//         >
//             <Container sx={{ py: { xs: 2, md: 3 } }}>
//                 <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
//                     <Box component={Link} to={paths.home}>
//                         <Logo color="light" height={32} width={122} />
//                     </Box>
                    
//                     <Box sx={{ ml: 'auto', display: { xs: 'inline-flex', md: 'none' } }}>
//                         <IconButton onClick={() => setVisibleMenu(!visibleMenu)}>
//                             <Menu />
//                         </IconButton>
//                     </Box>
//                     <Box
//                         sx={{
//                             width: '100%',
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'space-between',
//                             flexDirection: { xs: 'column', md: 'row' },

//                             transition: (theme) => theme.transitions.create(['top']),
//                             ...(matchMobileView && {
//                                 py: 6,
//                                 backgroundColor: 'background.paper',
//                                 zIndex: 'appBar',
//                                 position: 'fixed',
//                                 height: { xs: '100vh', md: 'auto' },
//                                 top: visibleMenu ? 0 : '-120vh',
//                                 left: 0,
//                             }),
//                         }}
//                     >
//                         <Box /> {/* Magic space */}
//                         <Navigation />
//                         {user && <DashboardNavigation />}
//                         {!user && <AuthNavigation />}
//                         {visibleMenu && matchMobileView && (
//                             <IconButton
//                                 sx={{
//                                     position: 'fixed',
//                                     top: 10,
//                                     right: 10,
//                                     zIndex: 1200, // Ensure this is higher than the menu
//                                 }}
//                                 onClick={() => setVisibleMenu(!visibleMenu)}
//                             >
//                                 <Close />
//                             </IconButton>
//                         )}
//                     </Box>
//                 </Box>
//             </Container>
//         </Box>
//     )
// }

// export default Header
