import React from 'react'
import MainLayout from '../Home/Layout'
import PageContainer from '../../components/PageContainer/PageContainer'
import { Box, Container, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { paths } from '../../paths'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Support = () => {
    return (
        <MainLayout>
            <PageContainer title='Support | Virtacc' description='This is Support page of Virtacc'>
                <Box id="#support" sx={{ backgroundColor: 'background.paper', position: 'relative', pt: 4, pb: { xs: 8, md: 10 } }}>
                    <Container >

                        <Box sx={{ width: '100%', mb: 3 }}>
                            <Typography
                                component="h6"
                                sx={{
                                    position: 'relative',
                                    fontSize: { xs: 30, md: 45 },
                                    letterSpacing: 1.5,
                                    fontWeight: 'bold',
                                    lineHeight: 1.3,
                                }}
                            >
                                Support
                            </Typography>
                        </Box>

                        <Grid container spacing={5}>
                            {/* <Grid container spacing={0} sx={{ flexDirection: { xs: 'column', md: 'unset' }, mb: 4 }}> */}
                            <Grid item xs={12} md={6}>
                                <Typography
                                    component="h2"
                                    sx={{
                                        position: 'relative',
                                        fontSize: { xs: 36, md: 46 },
                                        mt: 7,
                                        mb: 4,
                                        lineHeight: 1,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Maintain, Sustain, &{' '}
                                    <Typography
                                        component="mark"
                                        sx={{
                                            position: 'relative',
                                            color: 'primary.main',
                                            fontSize: 'inherit',
                                            fontWeight: 'inherit',
                                            backgroundColor: 'unset',
                                        }}
                                    >
                                        Optimize{' '}
                                    </Typography>
                                    Your Business
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>
                                    <p>Your success is a top priority at <Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} to={paths.home}>VIRTACC</Box> and we ensure that our customers receive the maximum return on their investment.</p>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={5} sx={{ position: 'relative' }}>
                                <Box sx={{ lineHeight: 0 }} >
                                    <img src="/assets/section/section-bg-dark.png" width={"100%"} alt="Hero img" />
                                </Box>
                            </Grid>

                        </Grid>

                        <Grid container spacing={5} >

                            <Grid item >
                                <Typography
                                    component="h2"
                                    sx={{
                                        position: 'relative',
                                        fontSize: { xs: 36, md: 46 },
                                        mt: 7,
                                        mb: 4,
                                        lineHeight: 1,
                                        fontWeight: 'bold',
                                    }}
                                    textAlign={'center'}
                                >
                                    {' '}
                                    <Typography
                                        component="mark"
                                        sx={{
                                            position: 'relative',
                                            color: 'primary.main',
                                            fontSize: 'inherit',
                                            fontWeight: 'inherit',
                                            backgroundColor: 'unset',
                                        }}
                                    >
                                        Advanced{' '}
                                    </Typography>
                                    Customer Support
                                </Typography>
                                <Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>
                                    <p>Each option provides specific service levels and capabilities, from online ticket submissions to chat and phone support, to managed services and onsite visits that help minimize the gap between your optimal solution and your actual solution.</p>
                                </Typography>
                                <PhoneOutlinedIcon fontSize='large' />
                                <p>Call</p>
                                <p>9876543210</p>
                                <ForumOutlinedIcon fontSize='large' />
                                <p>Chat</p>
                                <p>24x7</p>
                                <ConfirmationNumberOutlinedIcon fontSize='large' />
                                <p>Ticket</p>
                                <p><Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} to={'#'}>Submit a Ticket</Box></p>
                                <MailOutlineIcon fontSize='large' />
                                <p>Email</p>
                                <p><Box sx={{ textDecoration: 'none', color: 'primary.main' }} component={Link} to={'mailto:support@virtacc.com'}>support@virtacc.com</Box></p>
                            </Grid>

                        </Grid>

                    </Container>
                </Box>
            </PageContainer>
        </MainLayout>
    )
}

export default Support