// import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { paths } from '../paths';
import { ProtectedRoute } from '../components/ProtectedRoutes/ProtectedRoute';
import { ProtectedRouteSuperAdmin } from '../components/ProtectedRoutes/ProtectedRouteSuperAdmin';
// import LoadingSpinner from '../components/Core/LoadingSpinner'; // Simple loading spinner component

import Home from '../templates/Home/Home';
import Login from '../templates/Auth/SignIn/SignIn';
import Register from '../templates/Auth/SignUp/SignUp';
import Organizations from '../templates/Organizations/Organizations';
import Dashboard from '../templates/Dashboard/Dashboard';
// import AddUserForm from '../extra-pages/Dashboard/AddUserForm';
import Settings from '../templates/Dashboard/Settings/Settings';
import Account from '../templates/Dashboard/Account/Account';
import Customers from '../templates/Dashboard/Customers/Customers';
import Integrations from '../templates/Dashboard/Settings/Integrations';
import ResetPassword from '../templates/Auth/ResetPassword/ResetPassword';
import NotFound from '../templates/Errors/NotFound';
// import Practice from '../templates/Practice/algoliasearch/th';
import AccountOrganization from '../templates/Organizations/Account/Account';
import SettingsOrganizations from '../templates/Organizations/Settings/Settings';
import OrdersDashboard from '../templates/Dashboard/Orders/Orders';
import CustomersOrganizations from '../templates/Organizations/Customers/Customers';
import Upload from '../templates/Dashboard/Upload/Upload';
import Verify from '../templates/Auth/Verify/Verify';
import PrivacyPolicy from '../templates/Home/Legals/PrivacyPolicy';
import TermsOfService from '../templates/Home/Legals/TermsOfService';
import Products from '../templates/Dashboard/Products/Products';
import Reports from '../templates/Dashboard/Reports/Reports';
import Expenses from '../templates/Dashboard/Expenses/Expenses';
import Alerts from '../templates/Dashboard/Alerts/Alerts';
import PPC from '../templates/Dashboard/PPC/PPC';
import Recommendations from '../templates/Dashboard/PPC/Recommendations';
import AutomationLog from '../templates/Dashboard/PPC/AutomationLog';
import Planner from '../templates/Dashboard/Inventory/Planner';
import PurchaseOrders from '../templates/Dashboard/Inventory/PurchaseOrders';
import FBAShipments from '../templates/Dashboard/Inventory/FbaShipmnents';
import Suppliers from '../templates/Dashboard/Inventory/Suppliers';
import Campaigns from '../templates/Dashboard/Autoresponder/Campaigns';
import AutoresponderProducts from '../templates/Dashboard/Autoresponder/Products';
import AutoresponderOrders from '../templates/Dashboard/Autoresponder/Orders';
import Returns from '../templates/Dashboard/Moneyback/Returns';
import LostAndDamaged from '../templates/Dashboard/Moneyback/LostAndDamaged';
import FbaFeeChanges from '../templates/Dashboard/Moneyback/FbaFeeChanges';
import AlertsSettings from '../templates/Dashboard/Alerts/Settings';
import Users from '../templates/Dashboard/Settings/Users';
import Automation from '../templates/Dashboard/Settings/Automation';
import TellAFriend from '../templates/Dashboard/Settings/TellAFriend';
import Billing from '../templates/Dashboard/Settings/Billing';
import OrdersPage from '../templates/Practice/OrdersPage';
import Charts from '../templates/Dashboard/Charts/Charts';
import Support from '../templates/Support/Support';

// const Home = lazy<React.FC>(() => import('../templates/Home/Home'));
// const Login = lazy<React.FC>(() => import('../templates/Auth/SignIn/SignIn'));
// const Register = lazy<React.FC>(() => import('../templates/Auth/SignUp/SignUp'));
// const Organizations = lazy<React.FC>(() => import('../templates/Organizations/Organizations'));
// const CreateOrganization = lazy<React.FC>(() => import('../templates/Organizations/CreateOrganization'));
// const Dashboard = lazy<React.FC>(() => import('../templates/Dashboard/Dashboard'));
// const AddUserForm = lazy<React.FC>(() => import('../pages/Dashboard/AddUserForm'));
// const Upload = lazy<React.FC>(() => import('../templates/Uplaod/Upload'));
// const Settings = lazy<React.FC>(() => import('../templates/Dashboard/Settings/Settings'));
// const Account = lazy<React.FC>(() => import('../templates/Dashboard/Account/Account'));
// const Customers = lazy<React.FC>(() => import('../templates/Dashboard/Customers/Customers'));
// const Integrations = lazy<React.FC>(() => import('../templates/Dashboard/Integrations/Integrations'));
// const ResetPassword = lazy<React.FC>(() => import('../templates/Auth/ResetPassword/ResetPassword'));

const AppRoutes = () => (
    //   <Suspense fallback={<LoadingSpinner />}>
    <Routes>
        {/* Home */}
        <Route path={paths.home} element={<Home />} />
        {/* Auth */}
        <Route path={paths.auth.signIn} element={<Login />} />
        <Route path={paths.auth.signUp} element={<Register />} />
        <Route path={paths.auth.resetPassword} element={<ResetPassword />} />
        <Route path={paths.auth.verify} element={<Verify />} />
        {/* Dashboard */}
        <Route path={paths.dashboard.overview} element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path={paths.dashboard.products} element={<ProtectedRoute><Products /></ProtectedRoute>} />
        <Route path={paths.dashboard.orders} element={<ProtectedRoute><OrdersDashboard /></ProtectedRoute>} />
        <Route path={paths.dashboard.charts} element={<ProtectedRoute><Charts /></ProtectedRoute>} />
        <Route path={paths.dashboard.expenses} element={<ProtectedRoute><Expenses /></ProtectedRoute>} />
        <Route path={paths.dashboard.reports} element={<ProtectedRoute><Reports /></ProtectedRoute>} />
        {/* Dashboard - PPC */}
        <Route path={paths.dashboard.ppc.dashboard} element={<ProtectedRoute><PPC /></ProtectedRoute>} />
        <Route path={paths.dashboard.ppc.recommendations} element={<ProtectedRoute><Recommendations /></ProtectedRoute>} />
        <Route path={paths.dashboard.ppc.autoLog} element={<ProtectedRoute><AutomationLog /></ProtectedRoute>} />
        {/* Dashboard - Inventory */}
        <Route path={paths.dashboard.inventory.planner} element={<ProtectedRoute><Planner /></ProtectedRoute>} />
        <Route path={paths.dashboard.inventory.purchaseOrders} element={<ProtectedRoute><PurchaseOrders /></ProtectedRoute>} />
        <Route path={paths.dashboard.inventory.fbaShipments} element={<ProtectedRoute><FBAShipments /></ProtectedRoute>} />
        <Route path={paths.dashboard.inventory.suppliers} element={<ProtectedRoute><Suppliers /></ProtectedRoute>} />
        {/* Dashboard - Autoresponder */}
        <Route path={paths.dashboard.autoresponder.campaigns} element={<ProtectedRoute><Campaigns /></ProtectedRoute>} />
        <Route path={paths.dashboard.autoresponder.products} element={<ProtectedRoute><AutoresponderProducts /></ProtectedRoute>} />
        <Route path={paths.dashboard.autoresponder.orders} element={<ProtectedRoute><AutoresponderOrders /></ProtectedRoute>} />
        {/* Dashboard - Money Back */}
        <Route path={paths.dashboard.moneyback.lostAndDamaged} element={<ProtectedRoute><LostAndDamaged /></ProtectedRoute>} />
        <Route path={paths.dashboard.moneyback.returns} element={<ProtectedRoute><Returns /></ProtectedRoute>} />
        <Route path={paths.dashboard.moneyback.fbaFeeChanges} element={<ProtectedRoute><FbaFeeChanges /></ProtectedRoute>} />
        {/* Dashboard - Alerts */}
        <Route path={paths.dashboard.alerts.dashboard} element={<ProtectedRoute><Alerts /></ProtectedRoute>} />
        <Route path={paths.dashboard.alerts.settings} element={<ProtectedRoute><AlertsSettings /></ProtectedRoute>} />
        {/* Dashboard - Settings */}
        <Route path={paths.dashboard.settings.general} element={<ProtectedRoute><Settings /></ProtectedRoute>} />
        <Route path={paths.dashboard.settings.users} element={<ProtectedRoute><Users /></ProtectedRoute>} />
        <Route path={paths.dashboard.settings.integrations} element={<ProtectedRoute><Integrations /></ProtectedRoute>} />
        <Route path={paths.dashboard.settings.automation} element={<ProtectedRoute><Automation /></ProtectedRoute>} />
        <Route path={paths.dashboard.settings.tellAFriend} element={<ProtectedRoute><TellAFriend /></ProtectedRoute>} />
        <Route path={paths.dashboard.settings.billing} element={<ProtectedRoute><Billing /></ProtectedRoute>} />


        {/* <Route path={paths.dashboard.addUser} element={<ProtectedRoute><AddUserForm /></ProtectedRoute>} /> */}
        <Route path={paths.dashboard.customers} element={<ProtectedRoute><Customers /></ProtectedRoute>} />
        <Route path={paths.dashboard.account} element={<ProtectedRoute><Account /></ProtectedRoute>} />
        <Route path={paths.dashboard.upload} element={<ProtectedRoute><Upload /></ProtectedRoute>} />
        
        {/* Organizations */}
        <Route path={paths.organization.overview} element={<ProtectedRouteSuperAdmin><Organizations /></ProtectedRouteSuperAdmin>} />
        <Route path={paths.organization.customers} element={<ProtectedRouteSuperAdmin><CustomersOrganizations /></ProtectedRouteSuperAdmin>} />
        <Route path={paths.organization.settings} element={<ProtectedRouteSuperAdmin><SettingsOrganizations /></ProtectedRouteSuperAdmin>} />
        <Route path={paths.organization.account} element={<ProtectedRouteSuperAdmin><AccountOrganization /></ProtectedRouteSuperAdmin>} />
        
        {/* Support */}
        <Route path={paths.main.support} element={<Support />} />

        {/* Errors */}
        <Route path={paths.errors.notFound} element={<NotFound />} />
        {/* Practice */}
        <Route path={paths.practice.this} element={<OrdersPage />} />
        {/* Legals */}
        <Route path={paths.legals.privacyPolicy }  element={<PrivacyPolicy/>} />
        <Route path={paths.legals.termsOfService }  element={<TermsOfService/>} />
        {/* Others */}
        <Route path="*" element={<Navigate to={paths.errors.notFound} replace />} />
    </Routes>
    //   </Suspense>
);

export default AppRoutes;
